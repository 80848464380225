@font-face {
  font-family: "Chalkduster Regular";
  src: local("Chalkduster Regular"),
    url(../../fonts/Chalkduster.ttf) format("truetype");
}

.chalkboard-bg {
  background-color: #447189;
}

.chalkboard-fill {
  background-color: #4b4b4b;
}

.chalkboard-border {
  border-color: #f6a963;
}

.chalkboard-text {
  font-family: "Chalkduster Regular";
}

.dot-bg {
  background-color: #f6a963;
}
