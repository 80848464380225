.login-btn {
  background-color: #447189;
  border-color: #5e98b7;
}

.continue-btn {
  background-color: rgba(68, 113, 137, 0.6);
  border-color: #447189;
}

.text-color {
  color: #383c3a;
}
