.hide-scrollbar::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}

.hide-scrollbar {
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
}

.accordion-animation {
  max-height: 0;
}


.accordion-text-start {
  color: #dcba52;
}

.accordion-animation-after {
  max-height: 500px;
  border-top-width: 1px;
  border-color: white;
  padding-bottom: 1rem;
}

.pictureHeight {
  max-height: 250px;
}

.accordion-layout {
  flex-direction: row;
}

.accordion-bg {
  background-color: #447189;
}
