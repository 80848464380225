.headerColor {
  background-color: #383c3a;
}

.iconColor {
  color: #dcba52;
}

.overlay-bg {
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.2s ease-in-out; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.hide-scrollbar::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}

.hide-scrollbar {
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
}
