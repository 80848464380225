.app-background {
  background-color: #67a2bd;
}

.app-container-bg {
  background-color: #447189;
}

.app-orange-bg {
  background-color: #dcba52;
  color: #000;
}

body {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.label-text {
  color: rgb(220, 186, 82);
}

.trip-thumb {
  background-image: url('assets/svgtreasuremaptemplate.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.new-expedition-content-container {
  background-color: #DCBA52;
  color: black;
}

.dim-text {
  opacity: .7;
  font-size: x-small;
}

.flex-header {
  display: flex;
}

.flex-header img, .flex-header span {
  display: flex;
  flex-direction: column;
}

.flex-header img {
  width: 1.5rem;
  height: 1.5rem;
}

.flex-header span {
  line-height: 1.5rem;
  text-indent: .25rem;
}


.expedition-caption {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 3.5rem;
  
}

.expedition-title {
  background-image: url('./assets/svgskilt_bakgrunn.svg');
  background-position: center;
  width: 15rem;
  height: 3.75rem;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 1.125rem;
  color: #fff;
  font-weight: 600;
  font-size: medium;
  display: flex; 
  padding: 0 1rem;
}

.expedition-title-skilt-stang {
  background-image: url('./assets/svgskilt_stamme.svg');
  background-position: center;
  width: 15rem;
  height: 1.5rem;
  background-repeat: no-repeat;
}

.expedition-title span {  
  /* line-height: 3.5rem; */
  width: 11rem;
  margin-top: auto;
  margin-bottom: auto;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.expedition-caption p, .expedition-caption img {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trip-bottom-help {
  color: #fff;
}

.current-stop {
  display: flex;
}

.stop-id, .stop-description {
  display: flex;
  flex-direction: column;
  padding: .125em .5em;
}


.stop-id {
  font-size: 2em;  
}

.stop-control {
  display: flex;
  color: #DCBA52;
  font-size: large;
  font-weight: bold;
}

.stop-control-left, .stop-control-right {
  display: flex;
  flex-direction: column;  
  justify-content: center;
}


.expedition-description {
  color: #fff;
  padding: 0 1rem;
}
.expedition-description h4 {
  font-weight: bold;
  margin-bottom: .5rem;
}

.qr-scan section {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.stat-list {
  list-style: decimal;
  text-align: left;
}

.about-logo {
  max-width: 60%;
  margin: auto;
  padding: 1rem;
}