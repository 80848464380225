.filter-background {
  background-color: white;
  /* border-radius: 10px 10px 0 0; */
  box-shadow: 0px 4px 5px #888, 0px -1px 0px #888;
  max-height: 90%;
  overflow: auto;
}

.filter-item {
  border-bottom: 1px solid lightgray;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.menu-separator {
  border-right: 1px solid black;
}