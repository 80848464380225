.startquiz-btn {
  background-color: #dcba52;
  color: black;
}

.quiz-question {
  background-color: #447189;
  color: white;
}

.quiz-submit {
  background-color: #dcba52;
  border-color: white;
}

.quiz-cancel {
  background-color: white;
  border-color: #dcba52;
}

.quiz-points {

}